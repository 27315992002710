import React from 'react'

import './counter.scss'

import { FaEthereum } from 'react-icons/fa';

export default function Counter({ maxCount, count, setCount,remainingTokens }) {

    const handleCounterIncrease = () => {
        if(count < maxCount && count < remainingTokens) {
            setCount(count + 1);
        }
        else
            return
    }

    const handleCounterDecrease = () => {
        if(count > 1) {
            setCount(count - 1);
        }
        else
            return
    }

    return (
        <div className="counter-container">
          <h1 className="sale-type">Hello!</h1>
             <h1>You are eligible to mint up to {maxCount} tokens</h1>
            <div className="counter">
                <button onClick={() => handleCounterDecrease()}>-</button>
                <h1 style={{paddingLeft:"20px",paddingRight:"20px"}}>{count}</h1>
                <button onClick={() => handleCounterIncrease()}>+</button>
            </div>

            {/*<p className="eth-cost">*/}
            {/*    /!* <FaEthereum />  *!/*/}
            {/*    <br />*/}
            {/*    /!*{(count * price).toFixed(5)} ETH*!/*/}
            {/*</p>*/}
        </div>
    )
}
