import {useState, useEffect} from 'react';
import {useMetaMask} from "metamask-react";
import {ToastContainer, toast} from "react-toastify";

import './app.scss';
import "react-toastify/dist/ReactToastify.css";

import Header from './components/Header/Header';
import WalletLogin from './components/WalletLogin/WalletLogin';
import MintPassScreen from "./components/MintPassScreen/MintPassScreen";
import DebugBox from './components/DebugBox/DebugBox';

// Backend Imports
import {
  getListType,
  getSaleDetails,
  getWhitelist
} from "./utils/backendApi";

import {
  providerHandler,
  totalSupply,
  maxSupply,
  ownerRemainingCap,
  wl1,
  wl3,
  wl2,
  wl4,
  wl5,
  wl6,
  publicMint,
  pb,
  readWL1register,
  readWL2register,
  readWL3register,
  readWL4register,
  readWL5register,
  readWL6register,
  readPBregister,
  WL1mint,
  WL6mint,
  WL5mint,
  WL4mint, WL3mint, WL2mint,
} from "./web3/contractInteraction";

function App() {
  const { status, connect, account } = useMetaMask();
  const { ethereum } = window;

  const [passEligibility, setPassEligibility] = useState(false); // whitelist check

  const [passLoading, setPassLoading] = useState(true); // set loader state
  const [saleStatus, setSaleStatus] = useState(); // check whitelist sale status
  const [publicSaleStatus, setPublicSaleStatus] = useState(); // check whitelist sale status
  const [saleSupply, setSaleSupply] = useState();
  const [saleStartTime, setSaleStartTime] = useState();
  const [saleEndTime, setSaleEndTime] = useState();
  const [mintLimit, setMintLimit] = useState();
  const [mintAmount, setMintAmount] = useState(0);
  const [mintedToken, setMintedToken] = useState(0);
  const [remainingTokens, setRemainingTokens] = useState(0);
  const [listType, setListType] = useState(null);
  const [publicSaleCondtions, setPublicSaleCondtions] = useState({});

  const handleChainChange = async (reload) => {
    const currentChain = await window.ethereum.request({
      method: "eth_chainId",
    });

    const chainID = 1;

    if (currentChain !== `0x${chainID}`) {
      setPassLoading(true);

      toast("Switch to Ethereum mainnet", {
        position: "top-center",
        type: "warning",
        autoClose: false,
        hideProgressBar: true,
        toastId: currentChain,
      });

      window.ethereum
        .request({
          method: "wallet_switchEthereumChain",
          params: [{ chainId: `0x${chainID}` }],
        })
        .then(() => {
          if (reload) window.location.reload(false);
        });
    }
  };

  if (ethereum)
    ethereum.on("chainChanged", handleChainChange);

  // mint function
  const mintHandler = async () => {
    await handleChainChange(true);
    setPassLoading(true);

    try {
      if (listType) {
        switch (listType) {
          case 1:
            await WL1mint(passEligibility, mintAmount)
            break;
          case 2:
            await WL2mint(passEligibility, mintAmount)
            break;
          case 3:
            await WL3mint(passEligibility, mintAmount)
            break;
          case 4:
            await WL4mint(passEligibility, mintAmount)
            break;
          case 5:
            await WL5mint(passEligibility, mintAmount)
            break;
          case 6:
            await WL6mint(passEligibility, mintAmount)
            break;
          default:
            break;
        }
      } else {
        await publicMint(mintAmount)
      }

      toast.success("Transaction successful!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
      });

    } catch (e) {
      console.log(e.message);
      toast.error(
        e.code === "INSUFFICIENT_FUNDS"
          ? "Insufficient balance."
          : e.code === "UNPREDICTABLE_GAS_LIMIT"
          ? "Something went wrong."
          : "Transaction unsuccessful.",
        {
          position: "top-center",
          isLoading: false,
          autoClose: 5000,
          hideProgressBar: true,
        }
      );
    }

    // updated the minted amount
    if (passEligibility) {
      await checkMinted(passEligibility[1]);
    } else {
      let mintedToken = await readPBregister(account)
      setMintedToken(mintedToken)
    }

    setMintAmount(1);

    setPassLoading(false);
  };

  // function to check if sale started
  const checkSaleStatus = (saleStartTime, saleEndTime) => {
    const currentTimeSeconds = parseInt(Date.now() / 1000);
    if (saleStartTime < currentTimeSeconds && saleEndTime > currentTimeSeconds) {
      setSaleStatus("started")
    } else if (saleStartTime > currentTimeSeconds) {
      setSaleStatus("notStarted")
    } else {
      setSaleStatus("saleOver")
    }
  };

  const checkPublicSaleStatus = (saleStartTime, saleEndTime) => {
    const currentTimeSeconds = parseInt(Date.now() / 1000);
    if (saleStartTime < currentTimeSeconds && saleEndTime > currentTimeSeconds) {
      setPublicSaleStatus("started")
      return true
    } else if (saleStartTime > currentTimeSeconds) {
      setPublicSaleStatus("notStarted")
      return false
    } else {
      setPublicSaleStatus("saleOver")
      return false
    }
  };

  // // function to check supply / per wallet mint limit / mint price
  const checkSupply = (_totalSupply, _maxSupply, _ownerRemainingCap, saleConditions) => {
    const supplyAfterReserved = _maxSupply - _ownerRemainingCap - _totalSupply;
    const salesupply = Math.min(saleConditions.remainingTokens, supplyAfterReserved);
    setSaleSupply(salesupply);
    setMintLimit(saleConditions.buyLimitPerWallet)
  }

  // function to check if already minted
  const checkMinted = async (listTyp) => {
    let mintedTokens;
    switch (listTyp) {
      case 1:
        mintedTokens = await readWL1register(account)
        break;
      case 2:
        mintedTokens = await readWL2register(account)
        break;
      case 3:
        mintedTokens = await readWL3register(account)
        break;
      case 4:
        mintedTokens = await readWL4register(account)
        break;
      case 5:
        mintedTokens = await readWL5register(account)
        break;
      case 6:
        mintedTokens = await readWL6register(account)
        break;
      default:
        break;
    }
    setMintedToken(mintedTokens);
  };

  const accountSetup = async () => {
    setPassLoading(true);
    await handleChainChange(true);

    const account = await providerHandler();
    const pbsaleCondition = await pb()
    setPublicSaleCondtions(pbsaleCondition)
    const publicSale = checkPublicSaleStatus(pbsaleCondition.startTime, pbsaleCondition.endTime)
    let saleConditions;
    let userPassEligibility=false;
    if (!publicSale) {
       userPassEligibility = await getWhitelist(account);
      setListType(userPassEligibility ? userPassEligibility.signature[1] : null)
      setPassEligibility(userPassEligibility?.signature);

      if (userPassEligibility?.signature) {
        switch (userPassEligibility.signature[1]) {
          case 1:
            saleConditions = await wl1()
            break;
          case 2:
            saleConditions = await wl2()
            break;
          case 3:
            saleConditions = await wl3()
            break;
          case 4:
            saleConditions = await wl4()
            break;
          case 5:
            saleConditions = await wl5()
            break;
          case 6:
            saleConditions = await wl6()
            break;
          default:
            break;
        }
      } else {
        saleConditions = pbsaleCondition
      }
    } else {
      saleConditions = pbsaleCondition;
    }
    // check whitelist


    // console.log("saleConditions",saleConditions)
    setRemainingTokens(saleConditions.remainingTokens);
    setSaleStartTime(saleConditions.startTime)
    setSaleEndTime(saleConditions.endTime)

    const _totalSupply = await totalSupply();
    const _maxSupply = await maxSupply();
    const _ownerRemainingCap = await ownerRemainingCap();

    checkSaleStatus(saleConditions.startTime, saleConditions.endTime);
    checkSupply(_totalSupply, _maxSupply, _ownerRemainingCap, saleConditions);
    if (userPassEligibility?.signature && !publicSale) {
      await checkMinted(userPassEligibility.signature[1]);
    } else {
      let mintedToken = await readPBregister(account)
      setMintedToken(mintedToken)
    }

    setPassLoading(false);

  };

  useEffect(() => {
    if (account) {
      accountSetup();
    }
  }, [account]);

  return (
    <div className="app">
      <Header account={account} status={status} connect={connect}/>
      {status === "connected"
        ?
        <MintPassScreen
          publicSaleCondtions={publicSaleCondtions}
          setPassLoading={setPassLoading}
          passLoading={passLoading}
          passEligibility={passEligibility}
          saleStatus={saleStatus}
          saleStartTime={saleStartTime}
          saleSupply={saleSupply}
          mintLimit={mintLimit}
          mintedAmount={mintedToken}
          mintAmount={mintAmount}
          setMintAmount={setMintAmount}
          remainingTokens={remainingTokens}
          mintFunction={mintHandler}
        />
        : <WalletLogin status={status} connect={connect} mintLimit={mintLimit}/>
      }
      {/* <DebugBox makeToast={makeToast}/> */}
      <ToastContainer/>
      <div className="bg-cover"></div>
    </div>
  );
}

export default App;
