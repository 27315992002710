import React, {useEffect, useState} from "react";

import { MdOutlineErrorOutline } from "react-icons/md";
import { AiOutlineLoading } from "react-icons/ai";

import "./walletLogin.scss";

import Loader from "../Loader/Loader";

import {
  providerHandlerReadOnly,
  beasts,
  labGrownBeasts,
  postMint
} from "../../web3/contractInteraction";

export default function WalletLogin({ status, connect }) {
  const [mintLimit, setMintLimit] = useState({beasts: 0, labGrownBeasts: 0, postMint: 0});
  const [loading, setLoading] = useState(false);

  const connectWallet = () => {
    connect();
  };

  // function to check supply / per wallet mint limit / mint price
  // const checkSupply = async () => {
  //   setLoading(true);
  //   await providerHandlerReadOnly();
  //
  //   const beastsData = await beasts();
  //   const labGrownBeastsData = await labGrownBeasts();
  //   const postMintData = await postMint();
  //
  //   setMintLimit({
  //     beasts: parseInt(beastsData.purchaseLimit),
  //     labGrownBeasts: parseInt(labGrownBeastsData.purchaseLimit),
  //     postMint: parseInt(postMintData.purchaseLimit),
  //   });
  //
  //   setLoading(false);
  // }
  //
  // useEffect(() => {
  //   if(status === "notConnected")
  //     checkSupply();
  // }, []);

  useEffect(() => {
    if(status !=="initializing"){
      setLoading(false)
    }
  }, [status]);


  return (
    <div className="wallet-login-screen">
      <div className="wallet-login-container">
        {loading ? <Loader min={true}/> :
        <>
          <div className="token-card-container">

            {mintLimit.beasts ?
            <div className="token-card">
              <h2>Beast</h2>
              <p>{mintLimit.beasts} per Wallet</p>
            </div> : null}

            {mintLimit.labGrownBeasts ?
            <div className="token-card">
              <h2>Lab Grown Beast</h2>
              <p>{mintLimit.labGrownBeasts} per Wallet</p>
            </div> : null}

          </div>
        </>
        }
      </div>
    </div>
  );
}
