import { ethers, utils } from "ethers";
import config from "./config.json"
import abi from "./abi.json";

let contract;
const contractAddress = config.contractAddress;

export const providerHandler = async () => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const account = await provider.listAccounts();
    const address = account[0];
    const signer = provider.getSigner();
    contract = new ethers.Contract(contractAddress, abi, signer);
    return address;
};

export const wl1 = async () => {
  const n = await contract.WL1();
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    buyLimitPerWallet: n.buyLimit.toNumber(),
    remainingTokens: n.maxAvailable.toNumber()
  };
}

export const wl2 = async () => {
  const n = await contract.WL2();
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    buyLimitPerWallet: n.buyLimit.toNumber(),
    remainingTokens: n.maxAvailable.toNumber()
  };
}
export const wl3 = async () => {
  const n = await contract.WL3();
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    buyLimitPerWallet: n.buyLimit.toNumber(),
    remainingTokens: n.maxAvailable.toNumber()
  };
}
export const wl4 = async () => {
  const n = await contract.WL4();
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    buyLimitPerWallet: n.buyLimit.toNumber(),
    remainingTokens: n.maxAvailable.toNumber()
  };
}
export const wl5 = async () => {
  const n = await contract.WL5();
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    buyLimitPerWallet: n.buyLimit.toNumber(),
    remainingTokens: n.maxAvailable.toNumber()
  };
}
export const wl6 = async () => {
  const n = await contract.WL6();
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    buyLimitPerWallet: n.buyLimit.toNumber(),
    remainingTokens: n.maxAvailable.toNumber()
  };
}

export const pb = async () => {
  const n = await contract.PB();
  // console.log("n--")
  return {
    startTime: n.startTime.toNumber(),
    endTime: n.endTime.toNumber(),
    buyLimitPerWallet: n.buyLimit.toNumber(),
    remainingTokens: n.maxAvailable.toNumber()
  };
}

export const readWL1register = async(address)=>{
  const n = await contract.readWL1register(address);
  return n.toNumber()
}
export const readWL2register = async(address)=>{
  const n = await contract.readWL2register(address);
  return n.toNumber()
}
export const readWL3register = async(address)=>{
  const n = await contract.readWL3register(address);
  return n.toNumber()
}
export const readWL4register = async(address)=>{
  const n = await contract.readWL4register(address);
  return n.toNumber()
}

export const readWL5register = async(address)=>{
  const n = await contract.readWL5register(address);
  return n.toNumber()
}
export const readWL6register = async(address)=>{
  const n = await contract.readWL6register(address);
  return n.toNumber()
}
export const readPBregister = async(address)=>{
  const n = await contract.readPBregister(address);
  return n.toNumber()
}




// write functions
export const WL1mint = async ( signer, amount) => {
  console.log('WL1mint', signer, amount);
  const n = await contract.WL1mint(signer, amount);
  await n.wait();
  return n
}
export const WL2mint = async ( signer, amount) => {
  console.log('WL2mint', signer, amount);
  const n = await contract.WL2mint(signer, amount);
  await n.wait();
  return n
}
export const WL3mint = async ( signer, amount) => {
  console.log('WL3mint', signer, amount);
  const n = await contract.WL3mint(signer, amount);
  await n.wait();
  return n
}
export const WL4mint = async ( signer, amount) => {
  console.log('WL4mint', signer, amount);
  const n = await contract.WL4mint(signer, amount);
  await n.wait();
  return n
}
export const WL5mint = async ( signer, amount) => {
  console.log('WL5mint', signer, amount);
  const n = await contract.WL5mint(signer, amount);
  await n.wait();
  return n
}
export const WL6mint = async ( signer, amount) => {
  console.log('WL6mint', signer, amount);
  const n = await contract.WL6mint(signer, amount);
  await n.wait();
  return n
}
export const publicMint = async (  amount) => {
  console.log('publicMint',amount);
  const n = await contract.publicMint( amount);
  await n.wait();
  return n
}


// export const getSaleConditions = async (saleId) => {
//   const n = await contract.getSaleConditions(saleId,false);
//   return {
//     listType: n.listType.toNumber(),
//     remainingTokens: n.remainingTokens.toNumber(),
//     buyLimitPerWallet: n.buyLimitPerWallet.toNumber(),
//     mintPrice: utils.formatEther(n.mintPrice),
//     currency: n.currency,
//   };
// };
//
// export const getActiveSaleConditionId = async (listType) => {
//   const n = await contract.getActiveSaleConditionId(listType,false);
//   return n.toNumber();
// };

// export const getSaleTrackerValues = async (saleID,userAddress) => {
//   const n = await contract.getSaleTrackerValues(saleID,userAddress);
//   return n.toNumber();
// };

// export const allowListMint = async (price, signer, amount) => {
//   console.log(price);
//   console.log(signer);
//   console.log(amount);
//   const n = await contract.allowListMint(signer, amount, {value: utils.parseEther(price.toString())});
//   await n.wait();
//   return n
// }

export const totalSupply = async () => {
  const n = await contract.totalSupply()
  return n.toNumber()
}

export const maxSupply = async () => {
  const n = await contract.maxSupply()
  return n.toNumber()
}
export const ownerRemainingCap = async () => {
  const n = await contract.ownerRemainingTokens()
  return n.toNumber()
}


export const providerHandlerReadOnly = async () => {
  // const provider = new ethers.providers.Web3Provider(window.ethereum);
  // contract = new ethers.Contract(contractAddress, abi, provider);
};

// read functions
export const beasts = async () => {
  // const n = await contract.beasts();
  // return {
  //   startTime: n.startTime.toNumber(),
  //   endTime: n.endTime.toNumber(),
  //   purchaseLimit: n.purchaseLimit.toNumber(),
  //   maxAvailable: n.maxAvailable.toNumber(),
  //   price: utils.formatEther(n.price)
  // };
}

// export const readBeastsRegister = async(address)=>{
//   const n = await contract.readBeastsRegister(address);
//   return n.toNumber()
// }

export const labGrownBeasts = async () => {
  // const n = await contract.labGrownBeasts();
  // return {
  //   startTime: n.startTime.toNumber(),
  //   endTime: n.endTime.toNumber(),
  //   purchaseLimit: n.purchaseLimit.toNumber(),
  //   maxAvailable: n.maxAvailable.toNumber(),
  //   price: utils.formatEther(n.price)
  // };
}

// export const readLabGrownBeastsRegister = async(address)=>{
//   const n = await contract.readLabGrownBeastsRegister(address);
//   return n.toNumber()
// }

export const postMint = async () => {
  // const n = await contract.postMint();
  // return {
  //   startTime: n.startTime.toNumber(),
  //   endTime: n.endTime.toNumber(),
  //   purchaseLimit: n.purchaseLimit.toNumber(),
  //   maxAvailable: n.maxAvailable.toNumber(),
  //   price: utils.formatEther(n.price)
  // };
}

// export const readPostMintRegister = async(address)=>{
//   const n = await contract.readPostMintRegister(address);
//   return n.toNumber()
// }



// export const totalSupply = async () => {
//   const n = await contract.totalSupply()
//   return n.toNumber()
// }

// export const ownerRemainingTokens = async () => {
//   const n = await contract.reserve()
//   return n.toNumber()
// }

// write functions
// export const beastsMint = async (price, signer, amount) => {
//   console.log('beasts Mint', signer, amount, price);
//   const n = await contract.beastsSale(signer, amount, {value: utils.parseEther(price.toString())});
//   await n.wait();
//   return n
// }

// export const labGrownBeastsMint = async (price, signer, amount) => {
//   console.log('lab Grown Beasts Mint', signer, amount, price);
//   const n = await contract.labGrownBeastsSale(signer, amount, {value: utils.parseEther(price.toString())});
//   await n.wait();
//   return n
// }

// export const postMintSale = async (price, signer, amount) => {
//   console.log('post Mint', signer, amount, price);
//   const n = await contract.postMintSale(signer, amount, {value: utils.parseEther(price.toString())});
//   await n.wait();
//   return n
// }
