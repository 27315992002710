import React, {useState, useEffect} from "react";

import "./mintPassScreen.scss";

import {IoMdLink} from "react-icons/io"

import Loader from "../Loader/Loader";
import Counter from "../Counter/Counter";
import DebugBox from "../DebugBox/DebugBox";

import config from "../../utils/config.json"

export default function MintPassScreen({
                                         publicSaleCondtions,
                                         setPassLoading,
                                         passLoading,
                                         passEligibility,
                                         saleStatus,
                                         saleStartTime,
                                         saleSupply,
                                         mintLimit,
                                         mintedAmount,
                                         mintAmount,
                                         setMintAmount,
                                         mintFunction, remainingTokens
                                       }) {
  const [mintStatus, setMintStatus] = useState({ status: false, error: "Unknown Error - Try Reloading Page" });

  // Clamp number between two values with the following line:
  const clamp = (num, min, max) => Math.min(Math.max(num, min), max);

  const checkMintStatus = () => {
    if (saleStatus === "notStarted")
      setMintStatus({
        status: false, error: `Sale begins on ${new Date(saleStartTime * 1000).toLocaleString('en-US', {
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          hour12: true,
          minute: 'numeric',
        })}`
      });

    else if (saleStatus === "saleOver")
      setMintStatus({
        status: false,
        error: `Sorry, WL sale is over. Public sale begins on ${new Date(publicSaleCondtions.startTime * 1000).toLocaleString('en-US', {
          month: 'short',
          day: '2-digit',
          hour: '2-digit',
          hour12: true,
          minute: 'numeric',
        })}`,
        opensea: true
      });

    else if (saleStatus === "started") {
      if (saleSupply) {
        setMintStatus({ status: true, error: `Unknown Error - Try Reloading Page ` });
      }else{
        setMintStatus({ status: false, error: `Sorry, sale sold out!`, opensea: true });
      }
    }
  }

  useEffect(() => {
    if (saleStatus !== undefined && saleSupply !== undefined && mintLimit !== undefined) {
      checkMintStatus();
    } else {
      setPassLoading(true)
    }
    setMintAmount(1);
  }, [passEligibility, passLoading, saleStatus, mintLimit]);

  return (
    <div className="mint-pass-screen">
      {passLoading ?
        <Loader/> :
        <div className="mint-pass-container">
          {mintStatus.status
            ? <>
              {mintedAmount >= mintLimit
                ? <div className="minted">
                  <h1>Thanks for minting!</h1>
                  <h2>View your NFTs</h2>
                  <a href={config.openSea}>OpenSea</a>
                </div>
                : <div className="mint-container">
                  <Counter remainingTokens={remainingTokens} maxCount={clamp(mintLimit - mintedAmount, 1, saleSupply)}
                           count={mintAmount} setCount={setMintAmount}/>
                  <button
                    className="mint-button"
                    onClick={() => mintFunction()}
                  >
                    MINT
                  </button>
                </div>
              }
            </>
            : <div className="error">
              {saleStatus === "notStarted" ?
                <h2>{mintStatus?.error}</h2> :
                <h1>{mintStatus.error}
                  {mintStatus.opensea ? <a href={config.openSea}>OpenSea</a> : null}
                </h1>
              }
            </div>
          }
        </div>
      }
    </div>
  );
}
