import axios from "axios";

const BASE_URL = "https://jovynnmint.0xytocin.online/";

export const getWhitelist = async (address) => {
  try {
    const { data } = await axios.post(BASE_URL + "signer/unique", {
      address: address,
    });
    return data;
  } catch (err) {
      console.error("Backend-error: " + err.response.data);
      return false;
  }
};

export const getSaleDetails = async () => {
  try {
    const { data } = await axios.get(BASE_URL + `sale`);
    return data;
  } catch (err) {
      console.error("Backend-error: " + err.response.data);
    return false;
  }
};

export const getListType = async (address) => {
  try {
    const { data } = await axios.get(BASE_URL + `address?address=${address}`);
    return data;
  } catch (err) {
      console.error("Backend-error: " + err.response.data);
    return false;
  }
};